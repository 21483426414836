import { PUBLIC_DISABLE_SENTRY, PUBLIC_SENTRY_DSN, PUBLIC_SENTRY_ENV } from "$env/static/public";
import * as Sentry from "@sentry/sveltekit";
import { handleErrorWithSentry } from "@sentry/sveltekit";

Sentry.init({
  dsn: PUBLIC_SENTRY_DSN,
  environment: PUBLIC_SENTRY_ENV,
  enabled: PUBLIC_DISABLE_SENTRY !== "true",
  integrations: [new Sentry.Replay()],
  tracesSampleRate: 0.1,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

export const handleError = handleErrorWithSentry();
